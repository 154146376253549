export default defineNuxtPlugin((nuxtApp) => {
    // fade out when we start loading a new page
    nuxtApp.hook('page:start', () => {
        document.querySelector('body')?.classList.add('page-loading')
    })

    // fade in once the new page has finished loading
    nuxtApp.hook('page:loading:end', () => {
        document.querySelector('body')?.classList.remove('page-loading')
    })
})
