<script setup lang="ts">
    import markerSDK from '@marker.io/browser'

    useHead({
        titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} • ROOM` : 'ROOM'),
        script: [
            {
                //src: 'https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js',
            },
        ],
    })

    const { $preview } = useNuxtApp()
    const isDev = ref(import.meta.dev)
    const runtimeConfig = useRuntimeConfig()

    onMounted(() => {
        if (runtimeConfig.public.markerio.enabled) {
            markerSDK.loadWidget({
                project: '66a0c5d4382cf261a97427bd',
            })
        }
    })
</script>

<template>
    <Preview v-if="$preview" />
    <SiteHeader />
    <NuxtPage />
    <SiteFooter />
    <GridOverlay v-if="isDev" />
</template>

<style>
    @import 'tailwindcss/base';
    @import 'tailwindcss/components';
    @import 'tailwindcss/utilities';

    @import url('./assets/styles/gutters.css');
    @import url('./assets/fonts/NeueHaasGrotsek/style.css');
    @import url('./assets/styles/layout-grid.css');
    @import url('./assets/styles/staggered-transitions.css');
    @import url('./assets/styles/icon-arrow.css');
    @import url('./assets/styles/multiselect.css');

    @layer base {
        body {
            @apply transition-opacity;
            @apply font-sans;
            @apply bg-light;
            @apply text-dark;
        }

        #__nuxt {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100vh;
        }
    }

    @layer components {
        :root {
            --block-gap: 5rem;

            @screen md {
                --block-gap: 8rem;
            }

            @screen xl {
                --block-gap: 10rem;
            }

            @screen 2xl {
                --block-gap: 13rem;
            }
        }

        .separated {
            line-height: 1.2;

            > *.separate-inside li,
            > *:not(.separate-inside) {
                border-block: 1px solid;
                padding-block: 0.75rem;
            }

            > .separate-inside li {
                border-top: 1px solid;
            }

            > .separate-inside + :not(.separate-inside) {
                li {
                    border-bottom: 0;
                }
            }

            @media not all and screen(lg) {
                > *.separate-inside li + li {
                    border-top: 1px solid;
                }
            }
        }

        .block-gap-b + .block-gap-t,
        .block-gap-y + .block-gap-t,
        .block-gap-b + .block-gap-y,
        .block-gap-y + .block-gap-y {
            margin-top: 0;
        }

        .raw-content {
            p + p {
                @apply mt-4;
            }
        }
    }

    @layer utilities {
        .block-gap-y {
            margin-block: var(--block-gap);
        }

        .block-gap-t {
            margin-top: var(--block-gap);
        }

        .block-gap-b {
            margin-bottom: var(--block-gap);
        }

        .middle {
            @apply absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2;
        }
    }

    .entry-content p + p {
        margin-top: 1em;
    }

    /** transitions between pages */
    .page-enter-active,
    .page-leave-active {
        transition: all 0.4s;
    }

    .page-enter-from,
    .page-leave-to {
        opacity: 0;
        filter: blur(3px);
    }

    [data-aos='fade-up'] {
        transform: translate3d(0, 30px, 0);
    }

    body.page-loading {
        @apply opacity-35;
    }
</style>
