<script setup lang="ts">
    import { Entry } from '~/data/Entry'

    const locations = await Entry.all<Location>('locations')
    const physicalLocations = locations.filter((location) => location.has_physical_location)
</script>

<template>
    <ul class="separated transition-staggered">
        <li v-for="location in locations" class="flex gap-4">
            <h4>
                Call
                <span class="text-medium">{{ location.title }}</span>
            </h4>

            <span>
                <a :href="`tel:${location.phone_number}`" class="transition hover:text-medium">
                    {{ location.phone_number }}
                </a>
            </span>
        </li>
    </ul>

    <ul class="separated transition-staggered">
        <li v-for="location in physicalLocations" class="flex gap-4 !border-0">
            <h4>
                Visit
                <span class="text-medium" v-if="physicalLocations.length > 1">
                    {{ location.title }}
                </span>
            </h4>

            <div class="flex-1">
                <p>{{ location.address }}</p>
                <p v-if="location.opening_hours" class="mt-3">{{ location.opening_hours }}</p>
            </div>
        </li>
    </ul>
</template>

<style scoped>
    ul {
        --count: v-bind('locations.length');
        @apply flex flex-col justify-between lg:flex-row;
    }

    @media not all and screen(lg) {
        li:not(:first-child) {
            border-top: 0;
        }
    }

    @screen lg {
        li {
            width: calc(100% / var(--count) - 1.25rem * (var(--count) - 1) / var(--count));
        }
    }
</style>
