<script lang="ts" setup>
    import { OffsetItem } from '~/data/OffsetItem'
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { GlobalItem } from '~/data/GlobalItem'

    const header = ref<HTMLElement | null>(null)

    interface MenuInfo {
        info: Array<{
            title: string
            type_of_content: {
                value: 'text' | 'menu' | 'list'
            }
            text_content?: string
            menu?: {
                handle: string
            }
            list_items?: {
                title: string
                content: string
            }[]
        }>
    }

    const navOpen = useNavOpen()

    useEscapeKey(() => {
        navOpen.value = false
    })

    const menuInfo = await GlobalItem.fromHandle<MenuInfo>('menu_info')
    const navOffset = new OffsetGroup(new OffsetItem(3, 3), new OffsetItem(3, 3))
</script>

<template>
    <div class="header-logo gutter-l fixed transition md:absolute md:top-1/2">
        <NuxtLink
            to="/"
            class="pointer-events-auto block md:-translate-y-1/2"
            @click="useNavOpen().value = false"
        >
            <RoomLogo class="h-full" />
        </NuxtLink>
    </div>

    <header
        ref="header"
        class="site-header gutter-x pointer-events-none fixed inset-x-0 top-0 z-40 uppercase transition"
    >
        <div class="relative z-40 flex h-full justify-between before:absolute">
            <div
                class="text-header-foreground top pointer-events-auto absolute right-0 ml-auto flex text-xl md:gap-12 2xl:gap-14"
            >
                <NavDisplay
                    handle="top"
                    class="relative z-50 hidden xl:block"
                    linkClass="icon-arrow-northeast after:absolute after:-right-[.8em] after:bottom-[.35em]"
                />

                <MenuButton
                    @click="useNavOpen().value = !useNavOpen().value"
                    :open="useNavOpen().value"
                />
            </div>
        </div>

        <div
            :class="{
                'opacity-100': useNavOpen().value,
                'pointer-events-auto': useNavOpen().value,
            }"
            class="layout-grid fixed inset-0 z-20 h-screen overflow-auto bg-dark text-white opacity-0 transition ease-in-out"
        >
            <div class="flex h-full flex-col justify-between gap-8 py-8" :class="navOffset.classes">
                <NavDisplay
                    handle="main"
                    class="transition-staggered separated mt-14 text-2xl sm:mt-20 md:mt-0 md:text-3xl lg:text-4xl xl:text-5xl"
                    :arrows="true"
                    :item-class="
                        useNavOpen().value
                            ? 'opacity-100 translate-x-0 transition'
                            : 'opacity-0 -translate-x-8 transition'
                    "
                />

                <div class="menu-info text-sm text-white xl:text-base">
                    <Locations />

                    <ul class="separated transition-staggered">
                        <li class="flex gap-4">
                            <h3>Email</h3>
                            <a href="mailto:hello@room.design" class="transition hover:text-medium">
                                hello@room.design
                            </a>
                        </li>

                        <li class="flex items-center gap-4 !border-t-0">
                            <h3>Social</h3>
                            <NavSocials />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<style>
    :root {
        --header-logo-height: 50px;
        --header-padding: 0.7rem;

        @screen sm {
            --header-logo-height: 60px;
            --header-padding: 1rem;
        }

        @screen md {
            --header-logo-height: 85px;
            --header-padding: 1.5rem;
        }
    }

    body.nav-is-open {
        overflow: hidden;
    }

    body.is-scrolled-down-more {
        --header-logo-height: 30px;

        @screen sm {
            --header-logo-height: 40px;
        }

        @screen md {
            --header-logo-height: 60px;
        }
    }

    body.fixed-logo .header-logo {
        @apply fixed md:top-0;

        @screen md {
            padding-top: var(--header-padding);
        }

        a {
            @apply md:translate-y-0;
        }
    }

    .header-logo {
        top: var(--header-padding);
        z-index: 100;

        a {
            top: var(--header-padding);
        }

        figure {
            height: var(--header-logo-height);
        }
    }

    .site-header {
        height: calc(var(--header-logo-height) + var(--header-padding) * 2);
        padding-block: var(--header-padding);

        > div:first-child:before {
            @apply transition;
            inset-block: calc(var(--header-padding) * -1);
            inset-inline: calc(var(--gutter-width) * -1);
        }
    }

    @media not all and screen(md) {
        body:not(.with-feature-image) .site-header {
            z-index: 60;

            > div:first-child:before {
                @apply bg-light;
            }
        }

        body.nav-is-open .site-header > div:first-child:before {
            @apply bg-dark;
        }
    }

    body.fixed-logo:not(.with-feature-image):not(.nav-is-open),
    body.is-scrolled-down-more:not(.nav-is-open) {
        .site-header > div:first-child:before {
            @apply bg-light;
        }
    }

    .info-content {
        p + p {
            @apply mt-4;
        }

        a {
            @apply transition hover:text-medium;
        }
    }

    body {
        .text-header-foreground {
            @apply text-dark;
        }

        .header-logo figure {
            @apply bg-dark;
        }

        @screen md {
            .header-logo a:hover figure {
                @apply bg-white;
            }
        }
    }

    @supports (animation-timeline: scroll()) {
        body.with-feature-image:not(.is-scrolled-down-slightly):not(.is-scrolled-down-more) {
            .text-header-foreground {
                @apply text-white;

                button:hover,
                a:hover {
                    @apply text-light;
                }
            }

            .header-logo figure {
                @apply bg-white;
            }

            @screen md {
                .header-logo a:hover figure {
                    @apply bg-dark;
                }
            }

            &.nav-is-open {
                .header-logo a:hover figure {
                    @apply bg-medium;
                }
            }
        }
    }

    body.page-home.with-feature-image:not(.is-scrolled-down-slightly):not(.is-scrolled-down-more),
    body.nav-is-open {
        .text-header-foreground {
            @apply text-white;

            button:hover,
            a:hover {
                @apply text-light;
            }
        }

        .header-logo figure {
            @apply bg-white;
        }

        @screen md {
            .header-logo a:hover figure {
                @apply bg-dark;
            }
        }

        &.nav-is-open {
            .header-logo a:hover figure {
                @apply bg-medium;
            }
        }
    }

    .site-header .list-items {
        strong {
            @apply text-medium;
            @apply font-normal;
        }

        a {
            @apply transition hover:text-medium;
        }
    }

    .menu-info {
        h3,
        h4 {
            @apply w-20 sm:w-24 md:w-20 xl:w-24;
        }
    }
</style>
