export default defineNuxtPlugin((nuxtApp) => {
    let body: HTMLElement | null = null
    let headerHeight = 0
    let logoHeight = 0
    let viewportHeight = 0
    let featureImageAnimationRange = 500

    nuxtApp.hook('app:mounted', () => {
        body = document.body

        new EfficientScrollListener((scrollY: number) => {
            const logoFixed = scrollY > viewportHeight / 2 - logoHeight + 15
            body?.classList.toggle('fixed-logo', logoFixed)

            let isScrolledDownSlightly = false
            let isScrolledDownMore = false

            if (scrollY > 0) {
                if (scrollY > viewportHeight - headerHeight) {
                    isScrolledDownMore = true
                    isScrolledDownSlightly = false
                } else if (scrollY > featureImageAnimationRange - viewportHeight / 5) {
                    isScrolledDownMore = false
                    isScrolledDownSlightly = true
                }
            }

            body?.classList.toggle('is-scrolled-down-more', isScrolledDownMore)
            body?.classList.toggle('is-scrolled-down-slightly', isScrolledDownSlightly)
        })

        new EfficientResizeListener(setHeights)
    })

    // update when the page changes
    nuxtApp.hook('page:finish', () => {
        setHeights()
    })

    const setHeights = () => {
        viewportHeight = window.innerHeight
        headerHeight = document.querySelector('.site-header')?.clientHeight || 0
        logoHeight = document.querySelector('.header-logo')?.clientHeight || 0

        featureImageAnimationRange = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue('--animation-range'),
            10,
        )
    }
})
